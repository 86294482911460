import { useContext, useEffect, useState } from "react";

import { DiningTypes, ActivityTypes } from "../../constants/constants";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import { getName } from "../../assets/requests";

const RefreshItinerary = (id, view, setView, goTo) => {
  const { reservation, itineraryData, facilitiesData } =
    useContext(DataContext);

  const { setEditMode, setBookingSelections, bookingStatus } =
    useContext(SelectionContext);

  const resetBookingSelections = () => {
    try {
      setBookingSelections([]);
      setEditMode(null);
      const { itineraryDates } = itineraryData;

      itineraryDates.map(({ Data, Facilities }) => {
        Facilities.map(
          ({
            Quantity,
            Time,
            Id,
            Type,
            Title,
            ProviderCode,
            isParticipant,
            ParticipantLastName,
            ParticipantFirstName,
          }) => {
            setEditMode("edit");

            let screen = view.toLowerCase();
            if (view === "Entertainment") screen = "venue";

            if (Type.toLowerCase() === screen) {
              const preparedObj = {
                ReservationNumber: reservation.ReservationNumber,
                LastName: reservation.LastName,
                FirstName: reservation.FirstName,
                facilityGUID: [Id],
                day: Data,
                time: Time,
                Quantity: Number(Quantity),
                action: "none",
                timeBooked: Time,
                quantityBooked: Number(Quantity),
                facilityBooked: Id,
                type: Type,
                title: Title,
              };

              if ([...ActivityTypes, "WellnessActivity"].includes(view)) {
                if (Id === id) {
                  setEditMode("edit");
                  if (view === "Spa") {
                    preparedObj.providerCode = ProviderCode;
                    preparedObj.isParticipant = isParticipant;
                    preparedObj.facilityTitle = Title;

                    if (isParticipant) {
                      preparedObj.participantLastName = ParticipantLastName;
                      preparedObj.participantFirstName = ParticipantFirstName;
                    }
                  }

                  setBookingSelections((prevState) => [
                    ...prevState,
                    preparedObj,
                  ]);
                }
              } else {
                if (DiningTypes.includes(view)) {
                  setEditMode("edit");
                  preparedObj.restaurant = getName(
                    facilitiesData,
                    Id,
                    Type
                  );
                }

                setBookingSelections((prevState) => [
                  ...prevState,
                  preparedObj,
                ]);
              }
            }
          }
        );
      });

      if (DiningTypes.includes(view) && reservation[view]) {
        setEditMode("edit");
      }
    } catch (e) {
      console.log(
        `${new Date()} Error in checkIfItineraryExists func ion AcitivityPreviewLogic.js file ${e}`
      );
    }
  };

  return {
    resetBookingSelections,
  };
};

export default RefreshItinerary;
