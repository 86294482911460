const ROUTES = Object.freeze({
  Home: "/home",
  itinerary: "/itinerary",
  Dining: "/dining",
  Dinner: "/dining/dinner",
  Lunch: "/dining/lunch",
  Breakfast: "/dining/breakfast",
  Treats: "/treats",
  Golf: "/golf",
  Activities: "/activities",
  WellnessActivities: "/wellness-activities",
  Activity: "/activities/activity",
  WellnessActivity: "/wellness-activities/wellness-activity",
  Spas: "/spa",
  Spa: "/spa/availability",
  Entertainment: "/entertainment",
  "group-booking": "/dining/group",
  WhatsOnGuide: "/whatsonguide",
  HotelMapImage: "/hotel-map",
});

module.exports = {
  ROUTES,
}