import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import {
  managePriceTxt,
  obtainPrice,
} from "../../helpingFunctions/facilityAndServices";
import {
  getDayOfWeek,
  getStayingDates,
  isReservation3MonthsIn,
} from "../../helpingFunctions/utilities";
import SaveBookingHook from "../../hooks/SaveBookingHook";
import RequestHook from "../../hooks/RequestHook";
import SpaPreviewLogic from "./ActivityAndSpaLogic/SpaPreviewLogic";
import ActivityPreviewLogic from "./ActivityAndSpaLogic/ActivityPreviewLogic";
import { getSelectionByDate } from "../../helpingFunctions/selections";
import CheckBookingConflicts from "../../shared/validation/CheckBookingConflicts";
import HandleBookingConflicts from "../../shared/validation/HandleBookingConflicts";
import RefreshItinerary from "../../shared/RefreshItinerary/RefreshItinerary";

const Activity_Spa_PreviewLogic = (id, view, setView, goTo) => {
  const [object, setObject] = useState();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [priceTxt, setPriceTxt] = useState("");
  const [twoConflicts, setTwoConfilcts] = useState(false);
  const [alertStatus, setAlertStatus] = useState(null);
  const [activityTimeLimit, setActivityTimeLimit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [spaLoading, setSpaLoading] = useState({});
  const [spaAvailabilityForMobile, setSpaAvailabilityForMobile] = useState([]);
  const [spaFormData, setSpaFormData] = useState({lastName: "", firstName: ""});

  const {
    facilitiesData,
    availibility,
    reservation,
    itineraryData,
    selectedDate,
    setSelectedDate,
    wellnessActivities,
    setActivityToBeDeleted,
    activityToBeDeleted,
  } = useContext(DataContext);

  const {
    setEditMode,
    setBookingSelections,
    bookingSelections,
    bookingStatus,
    selectedQuantity,
    setMobileQuantity
  } = useContext(SelectionContext);

  const [pastDateSelection, setPastDateSelection] = useState(null);

  const { findConflicts, constructAlertMsg } = CheckBookingConflicts();
  const { showConflict, prepareConflictPopups, } = HandleBookingConflicts(view, setAlertStatus, setAlertMessage);
  const { resetBookingSelections } = RefreshItinerary(id, view);
  const { saveBookings } = SaveBookingHook(view, setPastDateSelection);

  const { findSpaAvailibility, spaAvailability } = SpaPreviewLogic(
    id,
    selectedDate,
    setAvailableTimes,
    setLoading,
    spaLoading,
    setSpaLoading,
    view,
    setSpaAvailabilityForMobile
  );
  const { findAvailibility } = ActivityPreviewLogic(
    id,
    selectedDate,
    setAvailableTimes,
    setLoading,
    view
  );

  const isReservationIn3Months = isReservation3MonthsIn(reservation.ArrivalDate)

  const findObject = (activityId) => {
    try {
      let thisObject;
      if (view === "WellnessActivity") {
        thisObject = wellnessActivities.find(
          ({ id }) => id === activityId
        );
      } else {
        thisObject = facilitiesData.find(
          ({ id, objType }) =>
            id === activityId && objType.toLowerCase() === view.toLowerCase()
        );
      }

      setObject(thisObject);
    } catch (e) {
      console.log(
        `${new Date()} Error in findObject func at Acitivty_Spa_PreviewLogic.js file: ${e}`
      );
    }
  };

  const adjustDates = (arrivalDate, departureDate, view) => {
    const dates = getStayingDates(arrivalDate, departureDate, view);

    setAvailableDates(dates);

    let quantityPerDay = {};
    dates.forEach((row) => {
      quantityPerDay[row] = 0
    })

    setMobileQuantity(quantityPerDay);
  };

  const manageDateAssign = () => {
    try {
      if (selectedDate?.endsWith("I")) {
        setSelectedDate(selectedDate.substr(0, selectedDate.length - 1));
        return;
      }

      setSelectedDate(availibility[0].Date);
    } catch (e) {
      console.log(
        `${new Date()} Error in manageDateAssign function in ActivityPreviewLogic file: ${e}`
      );
    }
  };

  const validateSelections = () => {
    let errorStr = "Please select ";

    if (selectedQuantity !== -1) {
      errorStr += `a time for ${getDayOfWeek(selectedDate)}`;
      return errorStr;
    }

    bookingSelections.sort((a, b) => new Date(a.day) - new Date(b.day));

    const nonValidSelections = bookingSelections.filter((selection) => selection.action !== "remove" && (!selection.hasOwnProperty("Quantity") || !selection.hasOwnProperty("time")));

    if (nonValidSelections.length === 0) {
      return false;
    }

    const selectionsLength = nonValidSelections.length;

    for (let i = 0; i < selectionsLength; i++) {
      const selection = nonValidSelections[i];
      
      if (!selection.Quantity || selection.Quantity === 0) {
        errorStr += `the number of guests for ${getDayOfWeek(selection.day)}`;
      } else if (!selection.time && selection.action !== "remove") {
        errorStr += `a time for ${getDayOfWeek(selection.day)}`;
      } else {
        return;
      }

      if (i === selectionsLength - 1) {
        errorStr += ".";
      } else if (selectionsLength > 1 && i === selectionsLength - 2) {
        errorStr += " and ";
      } else if (selectionsLength > 2) {
        errorStr += ", ";
      }
    }

    return errorStr.length < 20 ? "" : errorStr;
  };
  const manageBooking = async () => {
    try {
      const nonValid = validateSelections(bookingSelections);

      if (nonValid) {
        setAlertStatus("non-valid");
        setAlertMessage(nonValid);
        return;
      }

      const conflictArray = findConflicts(view, false, obtainPrice);
  
      if (conflictArray.length > 0) {
        const conflictDetails = [];
        for (let conflict of conflictArray) {
          conflictDetails.push({...constructAlertMsg(
            conflict,
            view,
            obtainPrice
          ), ...conflict, isResolved: false})
        }
        prepareConflictPopups(conflictDetails);
        
      } else {
        saveBookings();
      }
    } catch (e) {
      console.log(
        `${new Date()} error at manageBooking function in ActivityPreviewLogic file: ${e}`
      );
    }
  };
  const getActionButtonLabel = () => {
    const filtered = bookingSelections.filter((row) => row.action === "add");

    return bookingSelections.length === filtered.length;
  };

  const goBack = () => {
    setView({
      type: goTo,
    });
  };

  const closeAlert = () => {
    setAlertStatus(null);
  };

  
  const manageSpaFormData = (key, value) => {
    setSpaFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePastSelections = (pastDateSelection) => {
    const cancelRequests = pastDateSelection.filter(
      ({ action }) => action === "remove"
    );

    const hasSelections = cancelRequests.filter(({ Date }) => {
      const [day] = Date.split("T");
      const selectionMade = getSelectionByDate(bookingSelections, day);

      return selectionMade;
    });

    if (cancelRequests.length === pastDateSelection.length && hasSelections) {
      setAlertStatus("past-date-conflict-soft");
      setAlertMessage(
        "You can not cancel past reservation but you can add/remove the new one you selected. Do you wish to proceed?"
      );
    } else {
      setAlertStatus("past-date-conflict-strong");
      setAlertMessage(
        "It seems that you selected hours that are not availbale anymore. Please try choosing different options."
      );
    }
  };

  useEffect(() => {
    findObject(id);
    adjustDates(reservation.ArrivalDate, reservation.DepartureDate, view);
  }, []);

  useEffect(() => {
    if (object) {
      const price = managePriceTxt(object, facilitiesData, availibility)
      setPriceTxt(price)
    };
  }, [object, availibility]);

  useEffect(() => {
    if (itineraryData && !bookingStatus) resetBookingSelections();
  }, [itineraryData, bookingStatus]);

  useEffect(() => {
    if (availibility) manageDateAssign();
  }, [availibility]);

  return {
    object,
    availableTimes,
    availableDates,
    loading,
    spaLoading,
    alertStatus,
    selectedDate,
    alertMessage,
    priceTxt,
    spaAvailabilityForMobile,
    activityTimeLimit,
    isReservationIn3Months,
    setActivityTimeLimit,
    manageBooking,
    closeAlert,
    findAvailibility,
    findSpaAvailibility,
    goBack,
    spaFormData,
    setSpaFormData,
    manageSpaFormData,
    showConflict,
    getActionButtonLabel,
    spaAvailability
  };
};

export default Activity_Spa_PreviewLogic;