import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import axiosInstance from "../../url/createAxios";

const GolfLogic = (setView) => {
  const [pageData, setPageData] = useState({});
  const histroy = useHistory()
  const instance = axiosInstance();

  const getPageData = async () => {
    try {
      const { data } = await instance.get("/golf-page", {withCredentials: true});

      setPageData(data);
    } catch (error) {
      console.error(
        `${new Date()} Error at getPageData func in GolfLogic.js file ${
          error.message
        }`
      );
      setView()
    }
  };

  useEffect(() => {
    getPageData();
  }, []);
  return { pageData };
};

export default GolfLogic;
