import React from "react";

import { getName, getEntCombinedName } from "../../assets/requests";
import { getDayOfWeek } from "../../helpingFunctions/utilities";
import BookingPreviewItemLogic from "./BookingPreviewItemLogic";
import { ActivitiesTypes } from "../../constants/constants";
import WindowWidth from "../../hooks/WindowWidth";

import styles from "./booking-preview.module.css";

const BookingPreviewItem = ({
  day,
  time,
  facilityGUID,
  index,
  totalElements,
  action,
  facilityBooked,
  quantity,
  quantityBooked,
  view,
  firstName,
  lastName,
  isParticipant,
  participantFirstName,
  participantLastName,
  type,
}) => {
  const {
    getDiningAddOns,
    handleFailureIconClick,
    facilitiesData,
    wellnessActivities,
    loading,
    status,
    entertainmentData,
    filteredTreatsData,
  } = BookingPreviewItemLogic(day, totalElements, index);
  const markCancelled = action === "remove";

  const selectionQtn = markCancelled ? quantityBooked : quantity;
  const numberOfGuests =
  [...ActivitiesTypes, "Spa"].includes(view) 
      ? `for ${selectionQtn} ${selectionQtn === 1 ? "guest" : "guests"}`
      : "";
  const displayTitle =
    view === "Spa" && type === "Spa"
      ? `${getName(facilitiesData, facilityGUID, view)} - 
    ${isParticipant ? participantLastName : lastName}, ${
          isParticipant ? participantFirstName : firstName
        }`
      : view === "Entertainment" ? `${getEntCombinedName(entertainmentData, facilityGUID, day, time)}` : 
      `${getName(
        [...facilitiesData, ...wellnessActivities],
        facilityGUID,
        view,
        facilityBooked,
        action
      )} ${numberOfGuests}`;

  const { width } = WindowWidth();
  return (
    <>
      {view === "Spa" && action === "none" ? null : (
        <>
          <div className={styles.bookingPreviewItem}>
            <div
              style={{
                backgroundColor: "var(--color-primary)",
                width: "100%",
              }}
            >
              {action === "remove" ? (
                <div className={styles.cancelLine} />
              ) : null}
              <li
                className={styles.singleBooking}
                style={
                  (displayTitle.length > 60 ? { height: "80px" } : null,
                  { paddingTop: "15px", paddingBottom: "15px" })
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {width <= 700 ? (
                    <>
                      <h3
                        className={markCancelled ? styles.cancellingMark : ""}
                      >
                        {getDayOfWeek(day)} - {time}
                      </h3>
                      <div>
                        <h3
                          style={
                            markCancelled
                              ? { fontWeight: "100", width: "100%" }
                              : { width: "100%" }
                          }
                        >
                          {markCancelled ? (
                            <span style={{ fontWeight: "bold", width: "100%" }}>
                              {"Cancelling: "}
                            </span>
                          ) : null}
                          {displayTitle}
                        </h3>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <h3
                        className={markCancelled ? styles.cancellingMark : ""}
                      >
                        {getDayOfWeek(day)}
                      </h3>
                      <h3
                        className={markCancelled ? styles.cancellingMark : ""}
                      >
                        {time}
                      </h3>
                      <div>
                        <h3
                          style={
                            markCancelled
                              ? { fontWeight: "100", width: "100%" }
                              : { width: "100%" }
                          }
                        >
                          {markCancelled ? (
                            <span style={{ fontWeight: "bold", width: "100%" }}>
                              {"Cancelling: "}
                            </span>
                          ) : null}
                          {displayTitle}
                        </h3>
                      </div>
                    </>
                  )}
                </div>


              {/* TODO - Dining add ons related */}
                {/* {["Lunch", "Dinner"].includes(view)
                   && filteredTreatsData.length > 0 && getDiningAddOns(view, day, action) } */}
              </li>
            </div>
            {loading ? (
              <img
                className={styles.loader}
                src={"/img/info_icons/loading_.svg"}
                alt={"no img"}
              />
            ) : (
              <img
                onClick={
                  status === "success" || status === "no-change"
                    ? null
                    : handleFailureIconClick
                }
                className={styles.progressIndicator}
                src={`${
                  status === "success" || status === "no-change"
                    ? "/img/info_icons/done_.svg"
                    : "/img/info_icons/error_.svg"
                }`}
                alt={"no img"}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BookingPreviewItem;
