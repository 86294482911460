import { useContext, useEffect, useState } from "react";
import { DiningTypes, GROUP_LABELS } from "../../constants/constants";

import { DataContext } from "../../context/DataContext";
import { SelectionContext } from "../../context/SelectionContext";
import { obtainPrice } from "../../helpingFunctions/facilityAndServices";
import {
  constructStringWithComma,
  sumNumbers,
} from "../../helpingFunctions/helpingFunctions";
import { getName } from "../../assets/requests.js";
import { getDayOfWeek } from "../../helpingFunctions/utilities";
import { calcTotalGuests } from "../../helpingFunctions/groups";
import Heading from "../Heading/Heading";
const AllSelectionsPopUpLogic = (view, setView, mainObjectId) => {
  const {
    cancelSelections,
    setBookingStatus,
    bookingSelections,
    bookingStatus,
    setRefreshEntertainments,
    setHasExistingChanges,
    setEditMode,
    setDiningAddOnsSelections,
    diningAddOnsSelections,
    editMode,
  } = useContext(SelectionContext);
  const {
    setActivityToBeDeleted,
    setSelectedDate,
    groupData,
    reservation,
    facilitiesData,
    wellnessActivities,
    availibility,
    filteredTreatsData
  } = useContext(DataContext);
  const [errorDate, setErrorDate] = useState(null);
  const [btnDetails, setBtnDetails] = useState(null);

  const changeView = (goToView) => {
    setView({ type: goToView });
    cancelSelections();
    {/* TODO - Dining add ons related */}
    // cleanRemovedDiningAddOns();
    closePopUp();
  };

  const cleanRemovedDiningAddOns = () => {
    if (filteredTreatsData.length === 0 || !diningAddOnsSelections) return;
    const diningAddOnsSelectionsCopy = JSON.parse(JSON.stringify(diningAddOnsSelections));
    Object.entries(diningAddOnsSelectionsCopy[view]).forEach(
      ([key, value]) => {
        diningAddOnsSelectionsCopy[view][key] = value.filter((row) => row.action !== "remove")
      })

      setDiningAddOnsSelections(diningAddOnsSelectionsCopy);
  }

  const closePopUp = () => {
    setBookingStatus(null);
    setActivityToBeDeleted([]);
    setHasExistingChanges(false);
    setRefreshEntertainments(true);
    {/* TODO - Dining add ons related */}
    // cleanRemovedDiningAddOns();
    setEditMode("edit");
  };
  
  const handleNextBtnLabel = () => {
    const hasBreakfast = reservation.Breakfast;
    const hasLunch = reservation.Lunch;
    const hasDinner = reservation.Dinner;
    const detailsObj = { view: "", label: "" };

    if (DiningTypes.includes(view)) {
      if (!hasBreakfast) {
        detailsObj.view = "Breakfast";
        detailsObj.label = "Continue to breakfast bookings";
      }

      if (hasBreakfast) {
        if (!hasDinner) {
          detailsObj.view = "Dinner";
          detailsObj.label = "Continue to dinner bookings";
        } else {
          detailsObj.view = "Lunch";
          detailsObj.label = "Continue to lunch bookings";
        }
      }

      if (hasBreakfast && hasLunch && hasDinner) {
        detailsObj.view = "itinerary";
        detailsObj.label = "Continue to itinerary";
      }
    } else {
      detailsObj.view = "itinerary";
      detailsObj.label = "Continue to itinerary";
    }
    setBtnDetails(detailsObj);
  };

  useEffect(() => {
    if (bookingStatus.status === "after-response") {
      findErrors();
      handleNextBtnLabel();
    }
  }, [bookingStatus]);

  const handleErrorRedirect = () => {
    setBookingStatus(null);
    setActivityToBeDeleted([]);
    setSelectedDate(errorDate);
  };

  const findErrors = () => {
    try {
      const { results } = bookingStatus;
      const { Date } = results.find(({ Status }) => Status === "failure");

      setErrorDate(Date.split("T")[0]);
    } catch (e) {}
  };

  const splitDifferentObjects = () => {
    const mainObjectSelections = bookingSelections.filter(
      ({ facilityGUID }) => facilityGUID[0] === mainObjectId
    );
    const conflictSelections = bookingSelections.filter(
      ({ facilityGUID }) => facilityGUID[0] !== mainObjectId
    );

    return [mainObjectSelections, conflictSelections];
  };

  const detailData = (selectionsList) => {
    const [firstSelection] = selectionsList;
    const { facilityGUID, type } = firstSelection;
    const [id] = facilityGUID;

    const dataSource = view === "WellnessActivity" ? wellnessActivities : facilitiesData;
    const title = getName(dataSource, id, type);

    let msg = ``;
    const itemPrice = Number(
      obtainPrice(id, type, dataSource, availibility)
    );

    let removePriceTotal = 0;
    let addPriceTotal = 0;

    for (let index = 0; index < selectionsList.length; index++) {
      const { action, quantityBooked, Quantity } = selectionsList[index];
      if (action === "none") continue;
      if (action === "remove") {
        [...Array(parseInt(quantityBooked)).keys()].map((x) => {
          if (itemPrice > 0) {
            removePriceTotal += itemPrice;
          }
        });
      } else {
        [...Array(parseInt(Quantity)).keys()].map((x) => {
          if (itemPrice > 0) {
            addPriceTotal += itemPrice;
          }
        });
      }
    }

    const priceTotal = addPriceTotal - removePriceTotal;

    return { priceTotal, title };
  };

  const priceText = (object) =>
    `£${
      object.priceTotal > 0
        ? `${object.priceTotal} will be added`
        : `${object.priceTotal * -1} will be removed`
    } for ${object.title}`;

  const hotelText = () =>
    `to your account for settlement at ${
      reservation.Hotel.Title.toLowerCase().includes("hotel")
        ? reservation.Hotel.Title
        : `${reservation.Hotel.Title} hotel`
    }`;

  const prepareDetails = () => {
    let selections = [bookingSelections];
    const otherSelection = bookingSelections.some(
      ({ facilityGUID }) => facilityGUID[0] !== mainObjectId
    );

    if (otherSelection) selections = splitDifferentObjects();

    const detailsData = selections.map((selectionsList) => {
      return detailData(selectionsList);
    });

    let msg = "";

    const detailsDataLn = detailsData.length;
    const [mainObject] = detailsData;
    if (detailsDataLn > 1) {
      const [bookedObject, conflictObject] = detailsData;

      if (conflictObject.priceTotal !== 0 && bookedObject.priceTotal !== 0) {
        msg += `A Charge of ${priceText(
          conflictObject
        )} and a total charge of ${priceText(bookedObject)}`;
      } else {
        msg += `A Charge of ${priceText(
          conflictObject.priceTotal !== 0 ? conflictObject : bookedObject
        )}`;
      }

      msg += ` ${hotelText()}.`;
    } else {
      if (mainObject.priceTotal !== 0) {
        msg += `A total charge of ${priceText(mainObject)} ${hotelText()}.`;
      }
    }

    return { msg, title: mainObject.title };
  };

  const isFirstTimeBooking = () => {
    return bookingSelections.filter((row) => row.action === "add").length === bookingSelections.length 
  }

  const constructPopUpMessage = () => {
    const currentStatus = bookingStatus.status;
    let DisplayElement = null;

    if (currentStatus === "started" || currentStatus === "after-response") {
      DisplayElement = (
        <Heading
          txt={`We are verifying your bookings`}
          new_styles={{
            fontSize: "20px",
          }}
        />
      );
    } else if (currentStatus === "success") {
      if (
        isFirstTimeBooking() &&
        groupData?.items.length > 1 &&
        ["Breakfast", "Dinner"].includes(view)
      ) {
        DisplayElement = (
          <Heading
            txt={`You have successfully made your ${GROUP_LABELS.GroupRef.lowerCase} ${view.toLowerCase()} booking for ${calcTotalGuests(
              groupData.items
            )} people.`}
            new_styles={{
              fontSize: "20px",
            }}
          />
        );
      } else {
        if (DiningTypes.includes(view) || view === "Entertainment") {
          DisplayElement = (
            <Heading
              txt={`You have successfully ${
                !isFirstTimeBooking() ? "changed" : "booked"
              } your ${view.toLowerCase()} for your stay.`}
              new_styles={{
                fontSize: "20px",
              }}
            />
          );
        } else {
          const details = prepareDetails();
          DisplayElement = (
            <Heading
              txt={`
                You have successfully ${
                  !isFirstTimeBooking() ? "modified your" : "booked"
                } ${details.title} for your stay.
                ${details.msg}
              `}
              new_styles={{
                fontSize: "20px",
                lineHeight: "24px",
              }}
            />
          );
        }
      }
    } else {
      DisplayElement = (
        <Heading
          txt={`Unfortunately some of the selected times are no longer available. Please select a new time slot and try again.`}
          new_styles={{
            fontSize: "20px",
          }}
        />
      );
    }

    return DisplayElement;
  };

  return {
    changeView,
    closePopUp,
    errorDate,
    handleErrorRedirect,
    constructPopUpMessage,
    btnDetails,
  };
};

export default AllSelectionsPopUpLogic;
