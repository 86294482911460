import React from "react";
import InformationPage from "../../components/InformationPage/InformationPage";
import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";
import GolfLogic from "./GolfLogic";
import Button from "../../shared/Button/Button";
import { goToPage } from "../../helpingFunctions/linkChange";
import WindowWidth from "../../hooks/WindowWidth";

import styles from "./Golf.module.css";

const Golf = ({ setView }) => {
  const { pageData } = GolfLogic(setView);
  const { width } = WindowWidth();
  const isInMobile = width <= 600;

  return (
    <>
      <InformationPage
        image={pageData.Image?.formats?.large?.url}
        backdropFilter={"blur(5px)"}
        setView={setView}
      >
        {pageData.Title ? (
          <>
            <Heading
              txt={pageData.Title}
              fontSize={"40px"}
              fontFamily="Georgia"
              textTransform="uppercase"
              new_styles={{
                color: "#fff",
              }}
            />
            <InformationText
              content={pageData.Description}
              new_styles={{
                fontSize: "20px",
                color: "#fff",
                marginTop: "40px",
                whiteSpace: "pre-wrap",
              }}
            />
            <div className={styles.Golf__buttons}>
              {pageData.Link ? (
                <Button
                  txt={"Go to golf events"}
                  onClick={goToPage.bind(this, pageData.Link)}
                  new_styles={{
                    width: "fit-content",
                    backgroundColor: "var(--color-primary)",
                    marginRight: "50px",
                    color: "var(--color-secondary)"
                  }}
                />
              ) : null}
              {isInMobile ? (
                <Button
                  txt={"Go back"}
                  onClick={setView.bind(this, {})}
                  new_styles={{
                    width: "fit-content",
                    backgroundColor: "var(--color-primary)",
                    color: "var(--color-secondary)",
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <></>
        )}
      </InformationPage>
      {isInMobile ? null : (
        <Button
          txt={"Go back"}
          onClick={setView.bind(this, {})}
          new_styles={{
            width: "fit-content",
            height: "fit-content",
            backgroundColor: "var(--color-secondary)",
            color: "var(--color-primary)",
            position: "absolute",
            left: "25px",
            top: "10px",
          }}
        />
      )}
    </>
  );
};

export default Golf;
