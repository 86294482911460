import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import BookingType from "../../shared/BookingType/BookingType";
import NavBarLogic from "./NavBarLogic";
import Logo from "../../shared/Logo/Logo";
import WindowWidth from "../../hooks/WindowWidth";
import { DataContext } from "../../context/DataContext";
import { home_icon } from "../../assets/SVGIcons";
import { DiningTypes } from "../../constants/constants";
import { calculteDateDifference } from "../../helpingFunctions/utilities";

import styles from "./nav.module.css";

const NavBar = ({ setView, view }) => {
  const {
    handleBurgerClicked,
    logOut,
    homePage,
    isInWrongLocation,
    crossBurgerDiv,
    navReached,
  } = NavBarLogic(setView, view);
  const { reservation, isLate, facilitiesData } = useContext(DataContext);
  const { width } = WindowWidth();

  const SelfServiceTypes = [reservation?.CreditCard ? "View your registered card" : "Register your payment card", "Room Account"];
  if (!view || isInWrongLocation) return null;

  const isInRange = width > 1324;
  const showWellnessActivities =
    (reservation?.RateType[0].toLowerCase() === "w" &&
      !isNaN(reservation?.RateType[1])) ||
    reservation?.RateType === "wb hb";

  const navBarPosition = !navReached ? "relative" : "fixed";
  const hasTreats =
    facilitiesData.filter((row) => row.objType === "Treats").length > 0;

  const diference = calculteDateDifference(
    new Date(),
    new Date(reservation.ArrivalDate)
  );

  return (
    <>
      {navReached && isInRange ? (
        <div style={{ height: "75px", width: "100%" }} />
      ) : null}
      <nav
        className={`${styles.navBarReached}`}
        id={"navBar"}
        style={{
          position: navBarPosition,
          overflowY: "visible",
        }}
      >
        <div className={styles.logoWrapper}>
          <Logo
            width={"215px"}
            handleClick={homePage}
            cursor={"pointer"}
            hotelTitle={reservation.Hotel.Title}
          />
        </div>
        <ul className={styles.navList}>
          <BookingType
            txt={"Dining"}
            type={"Dining"}
            setView={setView}
            view={view}
            iconColor="iconWhite"
            navReached={navReached}
            setCrossBurgerDiv={handleBurgerClicked}
            isLate={isLate.isLate}
            new_styles={{ position: "relative" }}
            dropDownItems={DiningTypes}
          />
          <BookingType
            txt={"Activities"}
            path={`/`}
            type={"Activities"}
            setView={setView}
            view={view}
            iconColor="iconWhite"
            navReached={navReached}
            setCrossBurgerDiv={handleBurgerClicked}
          />
          {reservation.IsWithSpa ? (
            <BookingType
              txt={reservation.Hotel.SpaLabel}
              path={`/`}
              type={"Spas"}
              setView={setView}
              view={view}
              iconColor="iconWhite"
              navReached={navReached}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          ) : null}
          {reservation.Hotel.TwoLetterCode === "HP" ? (
            <BookingType
              txt={"Entertainment"}
              path={`/`}
              type={"Entertainment"}
              setView={setView}
              view={view}
              iconColor="iconWhite"
              navReached={navReached}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          ) : null}
          {hasTreats ? (
            <BookingType
              txt={"Treat Yourself"}
              type={"Treats"}
              setView={setView}
              view={view}
              iconColor="iconWhite"
              navReached={navReached}
              setCrossBurgerDiv={handleBurgerClicked}
              hideItem={isLate.isLate}
            />
          ) : null}
          {reservation.Hotel.TwoLetterCode === "HP" ? (
            <BookingType
              txt={"Golf"}
              type={"Golf"}
              setView={setView}
              view={view}
              iconColor="iconWhite"
              navReached={navReached}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          ) : null}
          <BookingType
            txt={"Itinerary"}
            type={"itinerary"}
            setView={setView}
            view={view}
            iconColor="iconWhite"
            navReached={navReached}
            setCrossBurgerDiv={handleBurgerClicked}
          />
          {showWellnessActivities && (
            <BookingType
              txt={"Wellness"}
              type={"WellnessActivities"}
              setView={setView}
              view={view}
              iconColor="iconWhite"
              navReached={navReached}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          )}
          {process.env.REACT_APP_PXP_ENABLED !== "0" && reservation.Hotel.ShowCreditCardTile && (new Date() > new Date(reservation.ArrivalDate) || diference?.allDayDifference <= 28) && (
            <BookingType
              txt={"Self-Service"} 
              type={"Self-service"}
              setView={setView}
              view={view}
              iconColor="iconWhite"
              navReached={navReached}
              setCrossBurgerDiv={handleBurgerClicked}
              new_styles={{ position: "relative" }}
              dropDownItems={SelfServiceTypes}
            />
          )}
        </ul>
        <div
          className={styles.titleWrapper}
        >
          <p onClick={logOut} className={styles.logOut}>
            Log Out
          </p>
        </div>
      </nav>
      <nav
        className={`${
          !crossBurgerDiv ? styles.mobileNav : styles.mobileNav__opened
        }`}
      >
        <div className={styles.mobileNav__burger_logo_wrapper}>
          <div onClick={homePage} className={styles.NavBar_home_icon}>
            {home_icon("var(--color-secondary)")}
          </div>
          <Logo
            width={"200px"}
            handleClick={homePage}
            cursor={"pointer"}
            hotelTitle={reservation.Hotel.Title}
          />

          <p onClick={logOut} className={styles.mobileNav__logout_kiosk}>
            Log Out
          </p>
          <div
            className={styles.mobileNav__burger}
            onClick={handleBurgerClicked}
          >
            <span className={`${styles.burger__line1} ${styles.line}`}></span>
            <span className={`${styles.burger__line2} ${styles.line}`}></span>
            <span className={`${styles.burger__line3} ${styles.line}`}></span>
          </div>
        </div>
        <ul className={styles.mobileNav__list}>
          <BookingType
            txt={"Dining"}
            type={"Dining"}
            setView={setView}
            view={view}
            iconColor={"iconWhite"}
            navReached={true}
            setCrossBurgerDiv={handleBurgerClicked}
            isLate={isLate.isLate}
            new_styles={{ position: "relative" }}
            dropDownItems={DiningTypes}
          />
          <BookingType
            txt={"Activities"}
            path={`/`}
            type={"Activities"}
            setView={setView}
            view={view}
            iconColor={"iconWhite"}
            navReached={true}
            setCrossBurgerDiv={handleBurgerClicked}
          />
          {reservation.IsWithSpa ? (
            <BookingType
              txt={reservation.Hotel.SpaLabel}
              path={`/`}
              type={"Spas"}
              setView={setView}
              view={view}
              iconColor={"iconWhite"}
              navReached={true}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          ) : null}
          {reservation.Hotel.TwoLetterCode === "HP" ? (
            <BookingType
              txt={"Entertainment"}
              path={`/`}
              type={"Entertainment"}
              setView={setView}
              view={view}
              iconColor={"iconWhite"}
              navReached={true}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          ) : null}
          {hasTreats ? (
            <BookingType
              txt={"Treat Yourself"}
              path={`/`}
              type={"Treats"}
              setView={setView}
              view={view}
              iconColor={"iconWhite"}
              navReached={true}
              setCrossBurgerDiv={handleBurgerClicked}
              hideItem={isLate.isLate}
            />
          ) : null}
          {reservation.Hotel.TwoLetterCode === "HP" ? (
            <BookingType
              txt={"Golf"}
              path={`/`}
              type={"Golf"}
              setView={setView}
              view={view}
              iconColor={"iconWhite"}
              navReached={true}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          ) : null}
          {showWellnessActivities && (
            <BookingType
              txt={"Wellness"}
              type={"WellnessActivities"}
              setView={setView}
              view={view}
              iconColor={"iconWhite"}
              navReached={true}
              setCrossBurgerDiv={handleBurgerClicked}
            />
          )}
          <BookingType
            txt={"Itinerary"}
            type={"itinerary"}
            setView={setView}
            view={view}
            iconColor={"iconWhite"}
            navReached={true}
            setCrossBurgerDiv={handleBurgerClicked}
          />
          {process.env.REACT_APP_PXP_ENABLED !== "0" && reservation.Hotel.ShowCreditCardTile && (new Date() > new Date(reservation.ArrivalDate) || diference?.allDayDifference <= 28) && (
            <BookingType
              txt={"Self-Service"}
              type={"Self-service"}
              setView={setView}
              view={view}
              iconColor={"iconWhite"}
              navReached={true}
              setCrossBurgerDiv={handleBurgerClicked}
              new_styles={{ position: "relative" }}
              dropDownItems={SelfServiceTypes}
            />
          )}
          <p onClick={logOut} className={styles.logOut}>
            Log Out
          </p>
        </ul>
      </nav>
      <div className={styles.createHeight} />
    </>
  );
};
export default withRouter(NavBar);
