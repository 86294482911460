import React, { useContext, useEffect, useState, useRef } from "react";
import { getDayOfWeek } from "../../helpingFunctions/utilities";
import { DataContext } from "../../context/DataContext";
import { dropDownArrowIcon } from "../../assets/SVGIcons";
import ItineraryCardExpandRow from "./ItineraryCardExpandRow";
import CheckBookingConflicts from "../../shared/validation/CheckBookingConflicts";
import Popover from "../../shared/Popover/Popover";
import InformationalBox from "../../shared/Containers/InformationalBox";
import PromotionalSpaceItineraryRow from "../../shared/Containers/PromotionalSpaceItineraryRow";
import WindowWidth from "../../hooks/WindowWidth";
import ItineraryCardLogic from "./ItineraryCardLogic";

import styles from "./itinerary_mobile_card.module.css";
import { BOOKING_TYPES, GROUP_LABELS, ActivitiesTypes } from "../../constants/constants";

const ItineraryCard = ({
  date,
  data,
  row,
  setView,
  expand,
  handleExpand,
  dayLineColor,
  promotionalRowSpaceContent,
}) => {
  const { width } = WindowWidth();
  const { setSelectedDate, reservation, groupData } = useContext(DataContext);
  const { getBookedDetails, getSummaryValue } = ItineraryCardLogic();
  const { checkItineraryConflicts } = CheckBookingConflicts();
  const [togglePopover, setTogglePopover] = useState(false);
  const [bookingConflicts, setBookingConflicts] = useState({});
  const [divHeight, setDivHeight] = useState(120);
  const ref = useRef(null);

  const InternalActivityLabel = BOOKING_TYPES.InternalActivity;
  const altNames = {
    Activity: "Activities booked",
    WellnessActivity: "Wellness booked",
    Spa: "Spa treatments booked",
    Extras: "Extras",
    [InternalActivityLabel]: BOOKING_TYPES.InternalActivity,
  };

  const handleClick = (viewObj) => {
    if (!["Spa", ...ActivitiesTypes, "Treats"].includes(viewObj.type)) {
      delete viewObj.id;
    }

    setSelectedDate(date + "I");
    setView(viewObj);
  };

  useEffect(() => {
    setBookingConflicts(checkItineraryConflicts(date));
  }, []);

  useEffect(() => {
    if (expand[date]) {
      setDivHeight(ref.current.clientHeight);
    }
  }, [expand]);
  const partOfGroupText = (diningType) => {
    if (!["Breakfast", "Dinner"].includes(diningType)) return null;

    const groupItem = groupData.items.find(
      ({ CRSNumber }) => CRSNumber === reservation.CRSNumber
    );
    if (!groupItem || !groupItem.changes || !groupItem.changes[date])
      return null;

    const isPartOfGroup = groupItem.changes[date][diningType];

    if (isPartOfGroup)
      return `  - You are part of "${groupData.groupName}" ${GROUP_LABELS.GroupRef.lowerCase}`;
    else return null;
  };
  // 90px = 15px (colored banner) + 75px (Section with day name + x sign)
  const showRowBanner =
    promotionalRowSpaceContent &&
    promotionalRowSpaceContent.ButtonRedirect === "Lunch" &&
    data.details.filter((row) => row.Type === "Lunch").length === 0 &&
    reservation.Breakfast;
  const extrasHeight = showRowBanner ? 95 : 90;
  return (
    <>
      <div
        className={styles.cardWrapper}
        style={{
          height: expand[date] ? `${divHeight + extrasHeight}px` : "230px",
          transition: "all 0.5s",
          position: "relative",
        }}
        onClick={() => handleExpand(date)}
      >
        <div
          style={{
            height: "15px",
            background: dayLineColor[date],
          }}
        />
        <div
          style={{
            display: "flex",
            overflow: "auto",
            overflowX: "hidden",
            height: expand[date] ? "75px" : "170px",
          }}
          onClick={() => (!expand[date] ? handleExpand(date) : null)}
        >
          <div
            style={{
              paddingTop: "5px",
              paddingLeft: "10px",
              width: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "var(--color-primary)",
            }}
          >
            <p style={{ fontSize: "20px" }}>
              <strong>Day</strong>
            </p>
            <p style={{ fontSize: "26px" }}>
              <strong>{row}</strong>
            </p>
          </div>
          {!expand[date] ? (
            <div
              style={{
                paddingTop: "5px",
                paddingLeft: "10px",
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "50%" }}>
                <p style={{ fontSize: "20px" }}>
                  <strong>{getDayOfWeek(date)}</strong>
                </p>
                {Object.entries(data.summary).map(([key, value], index) => (
                  <>
                    {[
                      "Breakfast",
                      "Lunch",
                      "Dinner",
                      "Arrival",
                      "Departure",
                      "Venue",
                    ].includes(key) && value.length > 0 ? (
                      <>
                        {["1", "9999", "9998"].includes(value[0]) &&
                        ["Breakfast", "Lunch", "Dinner", "Venue"].includes(
                          key
                        ) ? (
                          <div
                            style={{
                              paddingTop: "10px",
                              fontSize: "16px",
                            }}
                          >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <strong>{`${
                                key === "Venue" ? "Show" : key
                              }: `}</strong>
                              <div>
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    color: "var(--color-info)",
                                    paddingLeft: "5px",
                                    paddingBottom: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleClick({
                                      type:
                                        key === "Venue" ? "Entertainment" : key,
                                    })
                                  }
                                >
                                  {"book now"}
                                </p>
                              </div>
                            </div>

                            {(key === "Venue" && reservation.Venue) ||
                            (key === "Breakfast" && reservation.Breakfast) ||
                            (key === "Dinner" && reservation.Dinner) ? (
                              <InformationalBox
                                content={`(Opted for "Not Required")`}
                                boxNewStyles={{
                                  width: width < 465 ? "100px" : "170px",
                                }}
                              />
                            ) : null}
                          </div>
                        ) : (
                          <p style={{ paddingTop: "10px", fontSize: "16px" }}>
                            <strong>{`${
                              key === "Venue" ? "Show" : key
                            }: `}</strong>{" "}
                            {getSummaryValue(data, key)} {partOfGroupText(key)}
                          </p>
                        )}
                      </>
                    ) : null}
                  </>
                ))}
              </div>
              <div
                className={styles.rightSummaryContainer}
                style={{ width: "50%" }}
              >
                {Object.entries(data.summary).map(([key, value], index) => (
                  <>
                    {![
                      "Breakfast",
                      "Lunch",
                      "Dinner",
                      "Arrival",
                      "Departure",
                      "Venue",
                    ].includes(key) && value.length > 0 ? (
                      <>
                        <p style={{ paddingTop: "10px", fontSize: "16px" }}>
                          <strong>{`${altNames[key]}: `}</strong>
                        </p>
                        <p style={{ fontSize: "16px" }}>
                          {getSummaryValue(data, key)}
                        </p>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  paddingTop: "5px",
                  paddingLeft: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "100%", display: "flex", height: "30px" }}>
                  <p
                    style={{ fontSize: "20px" }}
                    onClick={() => setTogglePopover(!togglePopover)}
                  >
                    <strong>{getDayOfWeek(date)}</strong>
                  </p>
                  {togglePopover ? (
                    <Popover content={date} newStyles={{ width: "100px" }} />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>

        {!expand[date] ? (
          <div
            style={{
              height: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <span>{dropDownArrowIcon}</span>
          </div>
        ) : null}

        <div
          style={{
            width: "100%",
            opacity: expand[date] ? 1 : 0,
            transition: "all 0.2s ease-in-out",
          }}
          ref={ref}
        >
          <div style={{ display: expand[date] ? "block" : "none" }}>
            {data.details.map(
              (
                {
                  Id,
                  actStrapiId,
                  Time,
                  Title,
                  Type,
                  Quantity,
                  isParticipant,
                  FirstName,
                  LastName,
                  Location,
                  ParticipantFirstName,
                  ParticipantLastName,
                },
                index
              ) => (
                <>
                  {!["Arrival, Departure"].includes(Type) ? (
                    <ItineraryCardExpandRow
                      key={index}
                      date={date}
                      facilityId={Id}
                      bookedTime={Time}
                      Type={Type}
                      handleClick={handleClick}
                      bookingConflicts={bookingConflicts}
                      Quantity={Quantity}
                      isParticipant={isParticipant}
                      FirstName={FirstName}
                      LastName={LastName}
                      ParticipantFirstName={ParticipantFirstName}
                      ParticipantLastName={ParticipantLastName}
                      bookedDetails={
                        Type !== InternalActivityLabel
                          ? Type === "Treats" ? {Title: Title, Location: Location} : getBookedDetails(Id, Type, Location, actStrapiId)
                          : { Title }
                      }
                    />
                  ) : (
                    <div>{Title}</div>
                  )}
                </>
              )
            )}
            {promotionalRowSpaceContent ? (
              <>
                {showRowBanner ? (
                  <PromotionalSpaceItineraryRow
                    content={promotionalRowSpaceContent}
                    setView={setView}
                    redirectionDate={date}
                    setSelectedDate={setSelectedDate}
                    newStylesWrapper={{
                      marginBottom: "15px",
                      flexDirection: width < 500 ? "column" : "row",
                      height: showRowBanner && width < 500 ? "330px" : "215px",
                    }}
                    width={width}
                  />
                ) : (
                  <div style={{ height: "100px" }}></div>
                )}
              </>
            ) : (
              <div style={{ height: "100px" }} />
            )}
          </div>
          <div
            style={{
              height: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transform: "rotate(180deg)",
              opacity: expand[date] ? 1 : 0,
              transition: "all 1s ease-in",
            }}
          >
            <span
              style={{
                paddingTop: "22px",
                display: expand[date] ? "block" : "none",
              }}
            >
              {dropDownArrowIcon}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItineraryCard;
