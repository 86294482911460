import React from "react";

import styles from "./button.module.css";

const Button = ({
  backgroundColor,
  height,
  width,
  color,
  txt,
  onClick,
  opacity,
  pointerEvents,
  new_styles,
  minHeight,
  newStyles,
  isInThePast,
}) => {
  return (
    <button
      className={`${styles.warnerStayButton} ${isInThePast ? "disableElement" : ""}`}
      style={{
        backgroundColor,
        height,
        color,
        opacity,
        pointerEvents,
        width,
        minHeight,
        ...new_styles,
        ...newStyles,
      }}
      onClick={onClick}
    >
      {txt}
    </button>
  );
};

export default Button;
