import React from "react";
import { Link, useLocation } from "react-router-dom";
import Markdown from "markdown-to-jsx";

import VerticalLine from "../../shared/VerticalLine/VerticaLine";
import WelcomingModalLogic from "./WelcomingModalLogic";
import InformationText from "../../shared/InformationText/InformationText";

import styles from "./welcome.module.css";

const WelcomingModal = ({ data }) => {
  const {
    welcomeHeadingString,
    arrivalDate,
    reservation,
    homePageDesc,
    highlightContent,
    logOut
  } = WelcomingModalLogic(data);

  const location = useLocation();

  return (
    <>
      {reservation && location.pathname !== "/" ? (
        <div className={styles.welcomeModalWrapper}>
          <div className={styles.usernameWrapper}>
            <h3>Hi, {reservation.FirstName}</h3>
            <VerticalLine height={"15px"} marginRight={"0"} marginLeft={"0"} />
            <p
              onClick={logOut}
              className={styles.link}
            >
              Log Out
            </p>
          </div>
          <p className={styles.welcomingModalInfo}>
            {welcomeHeadingString} arriving {highlightContent(arrivalDate)}
            for {reservation.Nights} nights.
          </p>
          <InformationText
            lineHeight={"25px"}
            content={<Markdown>{homePageDesc}</Markdown>}
          />
        </div>
      ) : null}
    </>
  );
};

export default WelcomingModal;
