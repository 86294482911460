import React, { useState, createContext, useContext } from "react";
import { isValidDate, removeObject } from "../helpingFunctions/bookingHelp";

import { DiningTypes, ActivityTypes } from "../constants/constants";
import { DataContext } from "./DataContext";

export const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  let [bookingSelections, setBookingSelections] = useState([]);
  const [bookingStatus, setBookingStatus] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [hasExistingChanges, setHasExistingChanges] = useState(false);
  const [refreshEntertainments, setRefreshEntertainments] = useState(false);
  const [skipedDays, setSkipedDays] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(-1);
  const [mobileQuantity, setMobileQuantity] = useState({});
  const [diningAddOnsSelections, setDiningAddOnsSelections] = useState({});
  const {
    reservation,
    selectedDate,
    itineraryData,
    availibility,
    facilitiesData,
    wellnessActivities,
  } = useContext(DataContext);

  const checkIfIsValid = (currentSelection, [id], date, value, key) => {
    try {
      if (!id) return true;
      if (key === "time" && value) {
        const isValidSelection = isValidDate(date, value);
        return isValidSelection;
      }

      if (currentSelection && currentSelection.time) {
        const isValidSelection = isValidDate(date, currentSelection.time);
        return isValidSelection;
      }

      const { Facilities } = availibility?.find(({ Date }) => Date === date);
      const { Times } = Facilities.find(
        ({ FacilityGUID }) => FacilityGUID === id
      );
      const timesLn = Times.length;
      const { Time } = Times[timesLn - 1];

      const isValidSelection = isValidDate(date, Time);
      return isValidSelection;
    } catch (error) {
      console.log(
        `${new Date()} Error in checkIfIsValid func inside SelectionContext file ${
          error.message
        }`
      );
    }
  };

  const cancelSelections = async () => {
    setBookingSelections([]);
  };

  const getSelection = (value) =>
    bookingSelections.find(
      (selection) =>
        selection.day === selectedDate &&
        selection.facilityGUID?.some((el) => value?.find((val) => val === el))
    );

  const skipDayHandler = (day, view) => {
    setHasExistingChanges(true);
    if (skipedDays.includes(day)) {
      setSkipedDays((prevState) =>
        prevState.filter((skipedDay) => skipedDay !== day)
      );
      return;
    }

    setSkipedDays((prevState) => [...prevState, day]);
    if (editMode === "edit") setEditMode("edited");

    if (view !== "Entertainment") {
      const skipedItem = bookingSelections.find((prev) => prev.day === day);
      const selectionsWithoutSkiped = bookingSelections.filter(
        (prev) => prev.day !== day
      );

      if (!skipedItem) return;
      if (!skipedItem.title || skipedItem.action === "add") {
        setBookingSelections([...selectionsWithoutSkiped]);
        return;
      }

      const removeData = {
        Quantity: skipedItem.Quantity,
        Date: skipedItem.day,
        Id: skipedItem.facilityBooked,
        Time: skipedItem.timeBooked,
        Title: skipedItem.title,
        Type: skipedItem.type,
      };

      const removeObj = removeObject(reservation, removeData);

      setBookingSelections((prevState) => {
        const selectionsPlusRemoved = [...selectionsWithoutSkiped, removeObj];

        return selectionsPlusRemoved;
      });
    }
  };

  const updateQuantity = (facilityGUID, day, value, key, action, view = "") => {
    const selectionIndex = bookingSelections.findIndex(
      (selection) => selection.day === day
    );
    if (action) {
      if (action === "increment") {
        mobileQuantity[day]++;
      } else if (action === "decrement") {
        if (mobileQuantity[day] - 1 === 0) {
          return;
        }

        mobileQuantity[day]--;
      }

      setMobileQuantity(mobileQuantity);
    }

    if (selectionIndex === -1) {
      let preparedObj = {
        facilityGUID,
        day,
        [key]: value,
        ReservationNumber: reservation.ReservationNumber,
        LastName: reservation.LastName,
        FirstName: reservation.FirstName,
        action: "add",
        type: view,
      };
      setBookingSelections((prevStatus) => [...prevStatus, preparedObj]);
      return false;
    }

    if (editMode === "edit") setEditMode("edited");
    bookingSelections[selectionIndex][key] = value;

    if (
      bookingSelections[selectionIndex].quantityBooked === value &&
      bookingSelections[selectionIndex].timeBooked ===
        bookingSelections[selectionIndex].time
    ) {
      bookingSelections[selectionIndex].action = "none";
    } else {
      bookingSelections[selectionIndex].action =
        bookingSelections[selectionIndex].action === "add" ? "add" : "edit";
    }
    if (facilityGUID.length <= 1)
      bookingSelections[selectionIndex]["facilityGUID"] = facilityGUID;

    setBookingSelections((prevState) => [...prevState]);
    return true;
  };

  const checkForConfirmedReplacement = (facilityGUID, calledFrom) => {
    const replacementIndex = bookingSelections.findIndex(
      (row) =>
        row.facilityGUID[0] !== facilityGUID[0] &&
        row.action === "remove" &&
        row.day === selectedDate
    );

    if (replacementIndex !== -1) {
      const replacement = bookingSelections[replacementIndex];
      const toReplaceWithIndex = bookingSelections.findIndex(
        (row) =>
          row.facilityGUID[0] === facilityGUID[0] &&
          replacement.day === row.day &&
          replacement.timeBooked === row.time
      );
      if (toReplaceWithIndex !== -1) {
        return replacementIndex;
      }

      if (calledFrom === "createBookingSelectionNew") {
        return replacementIndex;
      }
    }

    return -1;
  };

  const createBookingSelectionNew = (
    facilityGUID,
    day,
    value,
    key,
    action,
    view,
    mobileQuantity
  ) => {
    try {
      if (!itineraryData || !availibility) return;

      const existing = bookingSelections.findIndex(
        (selection) => selection.day === day
      );

      const currentSelection = bookingSelections[existing];

      const isValid = checkIfIsValid(
        currentSelection,
        facilityGUID,
        day,
        value,
        key
      );

      if (!isValid) return;

      const detailsData = [...facilitiesData, ...wellnessActivities];
      if (editMode === "edit") setEditMode("edited");
      if (existing !== -1) {
        let items = [...bookingSelections];
        let item = { ...items[existing] };
        if (DiningTypes.includes(view) && item.facilityGUID.length !== 1) {
          item.facilityGUID = facilityGUID;
        }

        let details = null;
        if (facilityGUID.length === 1) {
          details = detailsData.find((row) => row.id === facilityGUID[0]);
          if (details) {
            item.restaurant = details.Title;
            item.title = details.Title;
          }
        }

        if (action === "add" && bookingSelections[existing].action !== "add") {
          item[key] = value;
          if (facilityGUID.length <= 1) item["facilityGUID"] = facilityGUID;

          item.action =
            item.timeBooked === item.time &&
            item.quantityBooked === item.Quantity &&
            item.facilityGUID.includes(item.facilityBooked)
              ? "none"
              : "edit";
        } else {
          item[key] = value;

          if (facilityGUID.length <= 1) {
            item["facilityGUID"] = facilityGUID;
          }

          const replacementIndex = checkForConfirmedReplacement(
            facilityGUID,
            "createBookingSelectionNew"
          );

          if (replacementIndex !== -1) {
            // Meaning that a replacement has happened and after that a new hour was selected, thus the need to remove
            // the replacement
            items = items.filter((row, index) => index !== replacementIndex);
          }
        }

        items[existing] = item;

        setBookingSelections(items);
      } else {
        let details = null;
        if (facilityGUID.length === 1)
          details = detailsData.find((row) => row.id === facilityGUID[0]);
        let preparedObj = {
          facilityGUID,
          day,
          [key]: value,
          ReservationNumber: reservation.ReservationNumber,
          LastName: reservation.LastName,
          FirstName: reservation.FirstName,
          action: "add",
          title: details?.Title,
          type: view,
        };

        if (facilityGUID.length === 1 && DiningTypes.includes(view)) {
          preparedObj.restaurant = details?.Title;
        }

        if ([...ActivityTypes, "WellnessActivity"].includes(view)) {
          if (selectedQuantity !== -1) {
            if (mobileQuantity) {
              preparedObj.Quantity = mobileQuantity;
            } else {
              preparedObj.Quantity = selectedQuantity;
            }
          }
          if (mobileQuantity && mobileQuantity !== 0) {
            preparedObj.Quantity = mobileQuantity;
          }
        }
        setBookingSelections((prevState) => [...prevState, { ...preparedObj }]);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in createBookingSelectionNew func inside SelectionContext.js file ${
          error.message
        }`
      );
    }
  };

  const removeBookingSelectionNew = (facilityGUID, day, value, key) => {
    const foundIndex = bookingSelections.findIndex(
      (selection) => selection.day === day
    );
    if (foundIndex === -1) return;
    const currentSelection = bookingSelections[foundIndex];
    const isValid = checkIfIsValid(
      currentSelection,
      facilityGUID,
      day,
      value,
      key
    );
    if (!isValid) return;
    if (Object.keys(mobileQuantity).length > 0) {
      mobileQuantity[day] = 0;
      setMobileQuantity(mobileQuantity);
    }

    if (
      bookingSelections[foundIndex].action === "none" ||
      bookingSelections[foundIndex].action === "edit"
    ) {
      let items = [...bookingSelections];
      let item = { ...items[foundIndex] };
      item.action = "remove";
      delete item.time;
      delete item.Quantity;

      const isDining = DiningTypes.includes(item.type);
      if (isDining) {
        item.facilityGUID = [];
      }

      if (isDining && !item.timeBooked) {
        setBookingSelections((prevState) =>
          prevState.filter((_, index) => index !== foundIndex)
        );
      } else {
        items[foundIndex] = item;
        setBookingSelections(items);
      }

      if (editMode === "edit") setEditMode("edited");
    } else {
      const replacementIndex = checkForConfirmedReplacement(
        facilityGUID,
        "removeBookingSelectionNew"
      );

      if (replacementIndex !== -1) {
        // Meaning that a replacement has happened and after that the new hour was unselected, thus the need to remove
        // the replacement
        setBookingSelections((prevState) =>
          prevState.filter(
            (row, index) => ![foundIndex, replacementIndex].includes(index)
          )
        );
        return;
      }

      setBookingSelections((prevState) =>
        prevState.filter((row, index) => index !== foundIndex)
      );
    }
  };

  const createEntertainmentSelection = (dataObj) => {
    const existing = getEntertainmentSelection(
      dataObj.facilityGUID[0],
      dataObj.day,
      dataObj.time
    );

    if (existing !== -1) {
      if (
        dataObj.action === "add" &&
        bookingSelections[existing].action === "remove"
      ) {
        const test = bookingSelections.find(
          (row, index) => row.action === "remove" && index !== existing
        );
        let items = [...bookingSelections];
        let item = { ...items[existing] };
        item.action = "none";
        items[existing] = item;
        setBookingSelections(items);
        setHasExistingChanges(!!test);
      }
    } else {
      setBookingSelections((prevState) => [...prevState, dataObj]);
      setEditMode(true);
      setHasExistingChanges(true);
    }
  };

  const removeEntertainmentSelection = (facilityId, day, time) => {
    const foundIndex = getEntertainmentSelection(facilityId, day, time);
    if (foundIndex === -1) return;

    if (bookingSelections[foundIndex].action === "none") {
      let items = [...bookingSelections];
      let item = { ...items[foundIndex] };
      item.action = "remove";
      items[foundIndex] = item;
      setBookingSelections(items);
      setHasExistingChanges(true);
      setEditMode(true);
    } else {
      setBookingSelections((prevState) =>
        prevState.filter((row, index) => index !== foundIndex)
      );
      setEditMode(true);
      setHasExistingChanges(true);
    }
  };

  const removeSkippedEntertainmentSelection = (facilityId, day, time) => {
    const foundIndex = getEntertainmentSelection(facilityId, day, time);
    if (foundIndex === -1) return;

    if (bookingSelections[foundIndex].action === "none") {
      let items = [...bookingSelections];
      let item = { ...items[foundIndex] };
      item.action = "remove";
      items[foundIndex] = item;
      setBookingSelections(items);
      setHasExistingChanges(true);
      setEditMode(true);
    } else {
      setBookingSelections((prevState) =>
        prevState.filter((row) => row.action !== "add" && row.Date === day)
      );
      setEditMode(true);
      setHasExistingChanges(true);
    }
  };

  const getEntertainmentSelection = (facilityId, day, time) => {
    return bookingSelections.findIndex(
      (row) =>
        row.facilityGUID.includes(facilityId) &&
        row.day === day &&
        row.time === time
    );
  };

  const createSpaSelection = (dataObj) => {
    if (dataObj.hasOwnProperty("foundIndex")) {
      let items = [...bookingSelections];
      let item = { ...items[dataObj.foundIndex] };
      item.action = "none";
      items[dataObj.foundIndex] = item;
      setBookingSelections(items);
      setEditMode(true);
    } else {
      setBookingSelections((prevState) => [...prevState, dataObj]);
      setEditMode(true);
    }
  };

  const removeSpaSelection = (cxnIndex) => {
    if (cxnIndex === -1) return;

    const selection = bookingSelections[cxnIndex];

    if (selection.action === "add") {
      setBookingSelections((prevState) =>
        prevState.filter((row, index) => index !== cxnIndex)
      );
    } else if (selection.action === "none") {
      let items = [...bookingSelections];
      let item = { ...items[cxnIndex] };
      item.action = "remove";
      items[cxnIndex] = item;
      setBookingSelections(items);
    }
    setEditMode(true);
  };

  const getSpaSelection = (
    facilityId,
    day,
    time,
    isParticipant,
    lastName,
    participantLastName,
    firstName,
    participantFirstName
  ) => {
    return bookingSelections.findIndex((row) => {
      if (row.isParticipant === isParticipant) {
        if (
          isParticipant
            ? row.participantLastName === participantLastName &&
              row.participantFirstName === participantFirstName
            : row.lastName === lastName && row.firstName === firstName
        ) {
          if (row.day === day) {
            if (row.time === time) {
              if (row.facilityGUID.includes(facilityId)) {
                return row;
              }
            }
          }
        }
      }
    });
  };

  return (
    <SelectionContext.Provider
      value={{
        bookingSelections,
        editMode,
        bookingStatus,
        skipedDays,
        setSkipedDays,
        cancelSelections,
        getSelection,
        setBookingSelections,
        setBookingStatus,
        setEditMode,
        skipDayHandler,
        createEntertainmentSelection,
        removeEntertainmentSelection,
        getEntertainmentSelection,
        hasExistingChanges,
        setHasExistingChanges,
        setRefreshEntertainments,
        refreshEntertainments,
        removeSkippedEntertainmentSelection,
        createBookingSelectionNew,
        removeBookingSelectionNew,
        updateQuantity,
        selectedQuantity,
        setSelectedQuantity,
        mobileQuantity,
        diningAddOnsSelections,
        setDiningAddOnsSelections,
        setMobileQuantity,
        createSpaSelection,
        removeSpaSelection,
        getSpaSelection,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
