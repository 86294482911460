import moment from "moment";

export const DiningTypes = ["Breakfast", "Lunch", "Dinner"];
export const ActivityTypes = ["Activity", "Spa", "Treat"];
export const ActivitiesTypes = ["Activity", "WellnessActivity"];

export const GroupTypes = ["Breakfast", "Dinner"];
export const NonScrollableTypes = [...DiningTypes, "Dining"];
export const GeorgiaBold = "Georgia_bold";
export const GeorgiaNormal = "Georgia_normal";

export const BOOKING_TYPES = {
  InternalActivity: "Internal Activity",
};

export const HOTEL_SELECTION_PACEHOLDER = "Select a hotel from the list here"; 

export const WarnerLogoText = "My Warner Stay";
export const GROUP_LABELS = {
  GroupRef: {
    uppercase: "Dining Party",
    lowerCase: "dining party",
    regular: "Dining party",
  },
  CreateGroup: "Create Dining Party",
  ManageGroup: "Manage Dining Party",
  ViewGroup: "View Dining Party",
  alertMessages: {
    planQuestion: "Are you planning on dining together with friends or family?",
    createGroupQuestion:
      "Creating a social dining party has never been easier. Would you like to create a social dining party?",
  },
};
export const NavBarDropDownTiles = ["Dining", "Self-service"]

export const initialDate = moment(new Date()).format("YYYY-MM-DD");

export const HELP_LINK = "https://www.warnerleisurehotels.co.uk/help/my-warner-stay";
